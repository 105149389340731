import { useGlobalState } from '@edeeone/juan-core/GlobalState';
const MODAL_SERVER_ACTION = 'modalServerAction';
const resultPath = [`${MODAL_SERVER_ACTION}result`];
const pendingPath = [`${MODAL_SERVER_ACTION}pending`];
const modalConfigurationPath = [`${MODAL_SERVER_ACTION}modalConfiguration`];
const actionPath = [`${MODAL_SERVER_ACTION}action`];
const actionPropsPath = [`${MODAL_SERVER_ACTION}actionProps`];
const parentFormContextPath = [`${MODAL_SERVER_ACTION}parentFormContext`];
let timer;
let _lastRequestId = 0;
export const useServerActionModal = (props) => {
    const { closeModalTimeoutMs = 1400 } = props ?? {};
    const { setState, removeState, getState } = useGlobalState();
    async function triggerModalServerAction(action, actionProps = {}, propModalConfiguration, parentFormContext) {
        const requestId = new Date().getTime();
        _lastRequestId = requestId;
        setState(pendingPath, true);
        const modalConfiguration = propModalConfiguration ?? getState(modalConfigurationPath);
        setState(modalConfigurationPath, modalConfiguration);
        setState(actionPath, action);
        setState(actionPropsPath, actionProps);
        setState(parentFormContextPath, parentFormContext);
        const result = await action.bind(null, {
            ...actionProps,
            inModal: true,
        })();
        if (requestId === _lastRequestId) {
            setState(resultPath, result);
            setState(pendingPath, false);
        }
    }
    function closeModal() {
        _lastRequestId = new Date().getTime(); // change id to "aborting" previous request
        removeState(resultPath);
        removeState(pendingPath);
        removeState(modalConfigurationPath);
        removeState(actionPath);
        removeState(actionPropsPath);
        removeState(parentFormContextPath);
    }
    const closeModalWithDelay = () => {
        setTimeout(() => {
            closeModal();
            clearTimeout(timer);
        }, closeModalTimeoutMs);
    };
    const refreshModal = async (newProps) => {
        const action = getState(actionPath);
        if (!action) {
            return;
        }
        const actionProps = getState(actionPropsPath);
        const newActionProps = { ...actionProps, ...newProps };
        await triggerModalServerAction(action, newActionProps);
    };
    return {
        triggerModalServerAction,
        closeModalWithDelay,
        closeModal,
        refreshModal,
        result: getState(resultPath),
        pending: getState(pendingPath),
        props: getState(modalConfigurationPath),
        parentFormContext: getState(parentFormContextPath),
    };
};
